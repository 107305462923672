import React from "react";
import NAIcon from '../../assets/img/sorryicon.png';

const NotAvailableIcon = () => {
    return(
        <>
            <div className="row d-flex justify-content-center">
                <div className="text-center">
                    <img alt="Not Available Icon" src={NAIcon} className="img-fluid" style={{padding:'30px', maxWidth: '275px', minHeight: '270px', maxHeight:'230px', opacity:'0.2'}}/>
                    <h2 style={{color:"lightgray"}}>Sorry, no data available.</h2>
                </div>  
            </div>
        </>
    )
}

export default NotAvailableIcon;