import axios from "axios";
import { apiPrefix } from "config";

export const uploadMedia = async(fileData, club_id, authtoken, onUploadProgress) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/upload/${club_id}`, fileData, {
        onUploadProgress,
        headers: {
            token: authtoken,
        }
    });
}

export const uploadMasterTemplateMedia = async(fileData, authtoken, onUploadProgress) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/upload`, fileData, {
        onUploadProgress,
        headers: {
            token: authtoken,
        }
    });
}