import axios from "axios";
import { apiPrefix } from "config"

export const getDashboardData = async(authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/reporting-dashboard`, {
        headers: {
            token: authtoken,
        }
    });
}
