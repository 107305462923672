import React, {useEffect, useState} from "react";
import { Card } from "primereact/card";
import { getDashboardData } from "api/dashboard";
import { useSelector } from "react-redux";
import LineChart from "components/dashboardChart/dashboardChart";
import { useDispatch } from 'react-redux';
import clublist from '../assets/json/patextdata.json';
import { useHistory } from 'react-router-dom';
function Dashboard() {
  const user = useSelector((state) => state.user);
  const [dashboardData, setDashboardData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {},
    });
    if (user?.authtoken) {
      getDashboardData(user?.authtoken)
        .then((response) => {
          setDashboardData(response.data);
          setChartData(response.data.clubs_activity_weekly);
        })
        .catch((error) => {
          if(error.response.status === 400) {
            localStorage.clear();
            dispatch({
              type: 'LOGOUT',
              payload: {
              },
            });
            history.push('/login');
          }
        });
    }
  }, [user?.authtoken]);
  
  
  const footer = () => {
    return(
      <>
        <div className="legend">
          {/* <i className="fas fa-circle text-info"></i>
          Clubs <i className="fas fa-circle text-danger"></i>
          Revenue <i className="fas fa-circle text-warning"></i>
          Transactions */}
        </div>
      </>
    )
  }
  const numbersStyle = {
    margin: '0px'
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <Card className="card-stats">
              <div className="row rowCustom">
                <div className="col-xs-5 order-xs-1">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-chart text-warning"></i>
                  </div>
                </div>
                <div className="col-xs-7 order-xs-2">
                  <div className="numbers">
                    <p className="card-category" style={numbersStyle}>Clubs</p>
                    <h4 className="card-title" style={numbersStyle}>{dashboardData?.dashboard_data?.total_clubs}</h4>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-4 col-sm-6">
            <Card className="card-stats">
              <div className="row rowCustom">
                <div className="col-xs-5 order-xs-1">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-light-3 text-success"></i>
                  </div>
                </div>
                <div className="col-xs-7 order-xs-2">
                  <div className="numbers">
                    <p className="card-category" style={numbersStyle}>Revenue</p>
                    <h4 className="card-title" style={numbersStyle}>$ {dashboardData?.dashboard_data?.total_revenue}</h4>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-4 col-sm-6">
            <Card className="card-stats">
              <div className="row rowCustom">
                <div className="col-xs-5 order-xs-1">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector text-danger"></i>
                  </div>
                </div>
                <div className="col-xs-7 order-xs-2">
                  <div className="numbers">
                    <p className="card-category" style={numbersStyle}>Transactions</p>
                    <h4 className="card-title" style={numbersStyle}>{dashboardData?.dashboard_data?.total_transactions}</h4>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row" style={{marginTop: '2%'}}>
          <div className="col-md-12">
            <Card title="Club Activity Chart" footer={footer}>
              {/* <p className="card-category"></p> */}
                <div className="ct-chart" id="chartHours">
                  <LineChart chartData={chartData}/>
                </div>
            </Card>
          </div>          
        </div>
      </div>
    </>
  );
}

export default Dashboard;
