import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { addAppVersion } from "api/appversionupdate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import updateversiontext from '../../assets/json/patextdata.json';
import { useDispatch } from 'react-redux';
import clublist from '../../assets/json/patextdata.json';
import '../../assets/css/primereactcustom.css';
const UpdateApp = () => {
  const [appVersion, setAppVersion] = useState("");
  const [appUrl, setAppUrl] = useState("");
  const [isMandatory, setIsMandatory] = useState(false);
  const [errors, setErrors] = useState({
    appVersion: "",
    appUrl: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {},
    });
  },[]);
  const validateDecimal = (value) => {
    // regex to allow only numbers or decimals (non-empty)
    const decimalRegex = /^\d+(\.\d+)?$/;
    return value !== "" && decimalRegex.test(value);
  };

  const validateUrl = (value) => {
    // You can use a more sophisticated URL validation logic here
    return value.startsWith("http://") || value.startsWith("https://");
  };

  const handleAppVersionChange = (e) => {
    const { value } = e.target;
    // Filter out non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, "");
    setAppVersion(numericValue);
    if (submitted) {
      setErrors({
        ...errors,
        appVersion: !validateDecimal(numericValue) ? updateversiontext.appVersion.appversionerror : "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    // Validation logic
    const newErrors = {
      appVersion: !validateDecimal(appVersion) ? updateversiontext.appVersion.appversionerror : "",
      appUrl: !validateUrl(appUrl) ? updateversiontext.appVersion.appurlerror : "",
    };

    setErrors(newErrors);

    // If there are no errors, you can proceed with form submission
    if (!newErrors.appVersion && !newErrors.appUrl) {
      const formData = {
        "app_version": parseFloat(appVersion),
        "app_url": appUrl,
        "mandatory": isMandatory
      };

      // Call API to save the data
      addAppVersion(formData, user?.authtoken)
        .then((response) => {
          if (response.status === 200) {
            toast.success(updateversiontext.appVersion.successmsg);
            setAppUrl("");
            setAppVersion("");
            setIsMandatory(false);
            // Clear errors after successful submission
            setErrors({
              appVersion: "",
              appUrl: "",
            });
            setSubmitted(false); // Reset submitted state
          }
        })
        .catch((error) => {
          dispatch({
            type: 'IS_ERROR',
            payload: {
              error: error
            },
          });
          history.push("/something-went-wrong");
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{updateversiontext.appVersion.appversiontitle}</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <label htmlFor={updateversiontext.appVersion.appversionid}>{updateversiontext.appVersion.appversion}</label>
                  <InputText
                    id={updateversiontext.appVersion.appversionid}
                    value={appVersion}
                    onChange={handleAppVersionChange}
                    className={`form-control ${errors.appVersion && submitted ? "is-invalid" : ""}`}
                  />
                  {errors.appVersion && submitted && (
                    <div className="invalid-feedback">{errors.appVersion}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor={updateversiontext.appVersion.appurl}>{updateversiontext.appVersion.appurl}</label>
                  <InputText
                    id={updateversiontext.appVersion.appurl}
                    value={appUrl}
                    onChange={(e) => setAppUrl(e.target.value)}
                    className={`form-control ${errors.appUrl && submitted && !validateUrl(appUrl) ? "is-invalid" : ""}`}
                  />
                  {errors.appUrl && submitted && !validateUrl(appUrl) && (
                    <div className="invalid-feedback">{errors.appUrl}</div>
                  )}
                </div>

                <div className="form-group form-check">
                  <Checkbox
                    inputId={updateversiontext.appVersion.mandatoryid}
                    checked={isMandatory}
                    onChange={(e) => setIsMandatory(e.checked)}
                    className="form-check-input"
                  />
                  <label htmlFor={updateversiontext.appVersion.mandatoryid} className="form-check-label ml-2">
                    {updateversiontext.appVersion.mandatorylabel}
                  </label>
                </div>

                <div className="form-group d-flex justify-content-center mt-4">
                  <Button className="btn-fill pull-right saveButton" label={updateversiontext.appVersion.save} onClick={handleSubmit} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateApp;
