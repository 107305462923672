import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({chartData}) => {
  const chartRef = useRef(null);
  const [formattedData, setFormattedData] = useState([]);
  const chartInstance = useRef(null);
  useEffect(() => {
    if (chartData) {
      const data = {
        labels: [],
        datasets: []
    };
    let counter = 0;
    for (const key in chartData) {
        const dataset = {
            label: key,
            data: [],
            borderColor: '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
            tension: 0.1,
            fill: false
        };
    
        chartData[key].forEach((item, index) => {
            const date = Object.keys(item)[0];
            const value = item[date];
            if(counter === 0) {
              data.labels.push(date);
            }
            dataset.data.push(value);
        });
        counter++;
        data.datasets.push(dataset);
    }
    
      setFormattedData(data);
    }
  }, [chartData]);

  useEffect(() => {
    if (formattedData?.labels?.length > 0) {
      const ctx = chartRef.current.getContext('2d');

      // Configuration options
      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: formattedData,
        options: options,
      });
    }
  }, [formattedData]);

  return <canvas ref={chartRef} />;
};

export default LineChart;