import React,{useState} from "react";
import {
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import SelectMultipleEntityFromDropdown from "components/CustomizedInputElements/SelectMultipleEntityFromDropdown";

const SetUpMasterAccount = () => {
  const options = [
    {value: '1',label: 'Prysm'},
    {value: '2',label: 'Phantom'},
    {value: '3',label: 'Zorro'},
    {value: '4',label: 'Vegas'},
  ];

  const [selectedOptions,setSelectedOptions] = useState([]);
  const [showDropdown,setShowDropdown] = useState(false);

  const handleSelectOption = (optionValue) => {
    setSelectedOptions([...selectedOptions,optionValue]);
    setShowDropdown(false);
  };

  const handleRemoveOption = (optionValue) => {
    const updatedOptions = selectedOptions.filter((value) => value !== optionValue);
    setSelectedOptions(updatedOptions);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Card title="Setup Master Account">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                        <span className="p-float-label">
                            <InputText id="name" className="form-control"/>
                            <label htmlFor="name" style={{fontSize:"14px"}}>Name</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                          <span className="p-float-label">
                              <InputText id="username" className="form-control"/>
                              <label htmlFor="username" style={{fontSize:"14px"}}>Username</label>
                          </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                    <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                      <span className="p-float-label" style={{borderBottom:"1px solid #fff"}}>
                          <Password inputId="password" feedback={false} style={{width:'100%'}} />
                          <label htmlFor="password" style={{fontSize:"14px"}}>Password</label>
                      </span>
                    </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                        <span className="p-float-label">
                            <InputText id="email" className="form-control" />
                            <label htmlFor="email" style={{fontSize:"14px"}}>Email</label>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <SelectMultipleEntityFromDropdown
                        options={options}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        selectedOptions={selectedOptions}
                        handleSelectOption={handleSelectOption}
                        handleRemoveOption={handleRemoveOption}
                      />
                    </div>
                  </div>
                  <br />
                  <Button
                    className="btn-fill pull-right saveButton"
                    type="submit"
                    variant="info"
                  >
                    Save
                  </Button>
                  <div className="clearfix"></div>
                </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetUpMasterAccount;
