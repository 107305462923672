import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import '../../assets/css/custom.css';
import '../../assets/css/primereactcustom.css';
import logo from '../../assets/img/logo.png';
import { login } from 'api/login';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logindata from '../../assets/json/patextdata.json';
const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    if (user && user?.authtoken) {
      history.push('/dashboard');
    }
  }, [user]);

  const handleLogin = async (event, email, password) => {
    event.preventDefault();

    // Validation: Check if username or password is empty
    if (!email) {
      setUsernameError(logindata.login.usernameError);
      setPasswordError(null);
      setLoginError(null);
      return;
    }

    if (!password) {
      setUsernameError(null);
      setPasswordError(logindata.login.passwordError);
      setLoginError(null);
      return;
    }

    const reqBody = { username: email, password: password };

    try {
      const response = await login(reqBody);

      if (response.status === 200) {
        dispatch({
          type: 'LOGGED_IN_USER',
          payload: {
            email: response.data.email,
            authtoken: response.data.token,
            role: response.data.role,
            club_id: response.data.club_id,
            user_id: response.data.user_id,
          },
        });
        history.push('/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setLoginError(error.response.data.msg);
      }
    }
  };

  return (
    <div className="container-fluid p-3 logincontainer">
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} style={{ height: '45vmin', pointerEvents: 'none', paddingBottom: '5%' }} alt="Logo" />
          </div>
          <div style={{ width: '50%', margin: '0 auto' }}>
            <form>
              <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                <span className="p-float-label">
                  <InputText
                    id={logindata.login.username.toLowerCase()}
                    className="loginusernameinput"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setUsernameError(null);
                    }}
                  />
                  <label htmlFor={logindata.login.username.toLowerCase()} style={{fontSize:"14px"}}>{logindata.login.username}</label>
                </span>
                {usernameError && <p style={{color: "#dc3545", display:"flex", fontSize:"80%"}}>{usernameError}</p>}
              </div>
              <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                <span className="p-float-label" style={{borderBottom:"1px solid #fff"}}>
                  <Password
                    inputId={logindata.login.password.toLowerCase()}
                    feedback={false}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError(null);
                    }}
                    style={{width:'100%'}}
                  />
                  <label htmlFor={logindata.login.password.toLowerCase()} style={{fontSize:"14px"}}>{logindata.login.password}</label>
                </span>
                {passwordError && <p style={{color: "#dc3545", display:"flex", fontSize:"80%"}}>{passwordError}</p>}
              </div>
              <div className="d-flex justify-content-between mb-4">
                <a href="#!" className="ml-auto" style={{fontSize:"12px", display:"none"}}>
                {logindata.login.forgotpassword}
                </a>
              </div>
              <Button
                label={logindata.login.signin}
                className="mb-2 w-100"
                style={{ backgroundColor: '#DEB215', color: 'white', border: '0px solid transparent' }}
                onClick={(event) => handleLogin(event,email, password)}
              />
              {loginError && <p style={{color: "#dc3545", display:"flex", fontSize:"80%"}}>{loginError}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
