import {combineReducers} from 'redux';
import { userReducer } from './userReducer';
import { clubReducer } from './clubReducer';
import { errorReducer } from './errorReducer';
const rootReducer = combineReducers({
    user: userReducer,
    club: clubReducer,
    error: errorReducer
});

export default rootReducer;