import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getClubs, deleteClub } from "api/club";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import '../../assets/css/custom.css';
import '../../assets/css/primereactcustom.css';
import {toast} from "react-toastify";
import { useHistory } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import ClubRightSidebar from "../clubcomponents/ClubRightSidebar";
import clublist from '../../assets/json/patextdata.json';
import {redirectToLoginIfNotAuthenticated} from "../../functions/authenticationUtils";
const ClubsList = () => {
  const [clubList, setClubList] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [clubData, setClubData] = useState(null);
  const [first, setFirst] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isdeleted, setIsdeleted] = useState(false);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  redirectToLoginIfNotAuthenticated(user);
  useEffect(() => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {},
    });
    getClubs(user?.authtoken)
      .then((response) => {
        setClubList(response.data.all_clubs);
      })
      .catch((error) => {
        if(error.response.status === 400) {
          localStorage.clear();
          dispatch({
            type: 'LOGOUT',
            payload: {
            },
          });
          history.push('/login');
        }
      });
  }, [user?.authtoken, isdeleted]);
  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter]);
  

  const handleShowProfile = (rowData) => {
    setClubData(rowData);
    setShowProfile(true);
  }
  const handleClubDelete = (id) => {
    deleteClub(id, user?.authtoken)
    .then((response) => {
      if(response.status === 200) {
        toast.success(clublist.clubsList.clubdeletemsg);
        setIsdeleted(!isdeleted);
      }
    }).catch((error) => {
      dispatch({
        type: 'IS_ERROR',
        payload: {
          error: error
        },
      });
      history.push("/something-went-wrong");
    })
  }
  const handleAccept = (id) => {
    handleClubDelete(id);
  }

  const reject = () => {
    
  }
  const confirm = (id) => {
    confirmDialog({
      message: clublist.clubsList.clubconfirmdialogmsg,
      header: clublist.clubsList.clubconfirmdialogheader,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      draggable: false,
      closeOnEscape: true,
      accept: () => handleAccept(id), 
      reject
    });
  };
  const handleEditClub = (param) => {
    history.push(`/edit-club?id=${param}`);
  }
  const actionButtons = (rowData) => {
    return(
      <>
        <Button type="button" className="p-button-sm p-button-text" icon="pi pi-pencil" onClick={()=> handleEditClub(rowData.ID)}></Button>
        <Button type="button" className="p-button-sm p-button-text" icon="pi pi-trash" onClick={()=>confirm(rowData.ID)}></Button>
      </>
    )
  }
  const handleTables = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/club-tables?club_id=${rowData.ID}`);
  }
  const handleAddTables = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/club-tables?club_id=${rowData.ID}&notable=${true}`);
  }
  const handleScreens = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/club-screens?club_id=${rowData.ID}`);
  }
  const handleAddScreens = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/club-screens?club_id=${rowData.ID}&noscreens=${true}`);
  }
  const handleAddUsers = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID
      },
    });
    history.push(`/manage-admin-users?id=${rowData.ID}&nousers=${true}`);
  }
  const handleView = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID,
      },
    });
    history.push(`/digital-sign-categories?id=${rowData.ID}`)
  }
  const handleClubUserLink = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID,
      },
    });
    history.push(`/manage-admin-users?id=${rowData.ID}`);
  }
  const categoryLink = (rowData) => {
    return(
      <>
        <Button label={clublist.clubsList.view} link style={{width:'fit-content'}} onClick={()=>handleView(rowData)}/>
      </>
    )
  }
  const userLink = (rowData) => {
    return(
      <>
        {
          rowData?.ClubUsersCount ? <Button label={rowData?.ClubUsersCount} link style={{width:'fit-content'}} onClick={()=>handleClubUserLink(rowData)}/>
          :
          <Button onClick={()=>handleAddUsers(rowData)} label="Add" link style={{width:'fit-content'}}/>
        }
      </>
    )
  }
  const reportLink = (rowData) => {
    dispatch({
      type: clublist.clubsList.currentclub,
      payload: {
        club_name: rowData.ClubName,
        club_id: rowData.ID,
      },
    });
    history.push('/club-report')
  }
  const TablesLink = (rowData) => {
    return(
      <>
        {
          (rowData.ClubTablesCount !== 0) ?
          <Button label={rowData.ClubTablesCount} link style={{width:'fit-content'}} onClick={()=>handleTables(rowData)}/>
          :
          <span onClick={()=>handleAddTables(rowData)} className="text-center" style={{padding:"1rem 1rem", cursor:"pointer",color:"#0B7AD1"}}>
            {clublist.clubsList.add}
          </span>
        } 
      </>
    )
  }
  const ScreensLink = (rowData) => {
    return(
      <>
        {
          (rowData.ClubScreensCount !== 0) ?
          <Button label={rowData.ClubScreensCount} link style={{width:'fit-content'}} onClick={()=>handleScreens(rowData)}/>
          :
          <span onClick={()=>handleAddScreens(rowData)} className="text-center" style={{padding:"1rem 1rem", cursor:"pointer",color:"#0B7AD1"}}>
            {clublist.clubsList.add}
          </span>
        } 
      </>
    )
  }
  const handleFilter = (e) => {
    setGlobalFilter((prevGlobalFilter) => e.target.value);
  }
  
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left custom-search-input">
            <i className="pi pi-search" />
            <InputText value={globalFilter} onChange={(e)=>handleFilter(e)} placeholder={clublist.clubsList.search} />
        </span>
      </div>
    );
  };
  const header= renderHeader();
  return (
    <>
      <ConfirmDialog />
      <ClubRightSidebar showProfile={showProfile} clubData={clubData} setShowProfile={setShowProfile}/>
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <Card title={clublist.clubsList.clublistheader}>
            <DataTable
              value={clubList && clubList}
              // header={header}
              paginator rows={25} rowsPerPageOptions={[25, 50]}
              paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              totalRecords={clubList?.length}
              first={first}
              globalFilter={globalFilter && globalFilter}
              globalFilterFields={[clublist.clubsList.clubname, clublist.clubsList.clubcity]}
              emptyMessage={clublist.clubsList.norecordsfound}
              className="p-datatable-striped"
            >
              <Column header={clublist.clubsList.clublogo} body={(rowData) => <img src={rowData.LogoUrl} className="liveclubLogo" alt={clublist.clubsList.clublogo} />} />
              <Column header={clublist.clubsList.clubnameheader} sortable body={(rowData) => <span className="link" onClick={() => handleShowProfile(rowData)}>{rowData.ClubName}</span>}/>
              <Column field={clublist.clubsList.clubcity} header={clublist.clubsList.clubcityheader} sortable />
              <Column header={clublist.clubsList.clubtablesheader} sortable body= {(rowData) => TablesLink(rowData)}/>
              <Column header={clublist.clubsList.screensheader} sortable body= {(rowData) => ScreensLink(rowData)}/>
              <Column header={clublist.clubsList.signsheader} body={(rowData) => categoryLink(rowData)}/>
              <Column header={clublist.clubsList.clubusersheader} body={(rowData) => userLink(rowData)}/>
              <Column style={{minWidth: '160px'}} header={clublist.clubsList.clubreports} body={(rowData) => <Button onClick={() => reportLink(rowData)} label={clublist.clubsList.clubreportsheader} className="p-button p-component saveButton" />} />
              <Column style={{ flex: '0 0 4rem', minWidth:'110px' }} header={clublist.clubsList.actions} body={(rowData)=>actionButtons(rowData)}/>
            </DataTable>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ClubsList;
