import axios from "axios";
import { apiPrefix } from "config";

export const addClubUser = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/clubs/${formData.club_id}/user`, formData, {
        headers: {
            token: authtoken,
        } 
    });
}

export const updateClubUserData = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/clubs/${formData.club_id}/user`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const getClubUsers = async(id,authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/clubs/${id}/users`, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteClubUser = async(rowData, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/clubs/${rowData.ClubID}/users/${rowData.Userid}`, {
        headers: {
            token: authtoken,
        }
    });
}