import axios from "axios";
import { apiPrefix } from "config";

export const getMasterDigitalSignCategories = async(authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/digital-sign-types`, {
        headers: {
            token: authtoken,
        }
    });
}
export const getMasterDigitalSignByCategory = async(id, authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/digital-signs-by-type/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}
export const addMasterDigitalSignCategory = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/digital-sign-type`, formData, {
        headers: {
            token: authtoken,
        }
    });
}
export const updateMasterDigitalSignCategory = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/digital-sign-type`, formData, {
        headers: {
            token: authtoken,
        }
    });
}
export const deleteMasterDigitalSignCategory = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/digital-sign-type/${id}`, {
        headers: {
            token: authtoken
        }
    });
}

export const addMasterDigitalSign = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/digital-sign`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const updateMasterDigitalSign = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/digital-sign`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteMasterDigitalSign = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/digital-sign/${id}`, {
        headers: {
            token: authtoken
        }
    });
}