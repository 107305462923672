// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-class-success{
    background-color:  #ccffcc;
}
.row-class-pending{
    background-color:  #ffffcc;
}
.row-class-rejected{
    background-color:  #ffe6e6;
}
.row-class-paid{
    background-color:  #cce6ff;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/datatable.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".row-class-success{\n    background-color:  #ccffcc;\n}\n.row-class-pending{\n    background-color:  #ffffcc;\n}\n.row-class-rejected{\n    background-color:  #ffe6e6;\n}\n.row-class-paid{\n    background-color:  #cce6ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
