import React, {useEffect} from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import routes from "routes.js";
import sidebarImage from "assets/img/sidebar-3.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function Admin({type, perspective}) {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const {user} = useSelector((state) => ({...state}));
  const history = useHistory();
  const getRoutes = (routes) => {
    return routes.map((prop, key) => (
      <Route
        path={prop.layout + prop.path}
        render={(props) => (
          <prop.component
            {...props} 
            {...(type ? { type } : {})}
            {...(perspective ? { perspective } : {})}
          />
        )}
        key={key}
      />
    ));
  };
  useEffect(() => {
    if(!(user && user.authtoken)) {
      history.push("/")
    }
  },[user]);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Admin;
