import React, { useState, useEffect } from "react";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import clubtable from '../../assets/img/clubtable.png';
import { getClubTables, addClubTable, deleteClubTable, generateTableQR, updateClubTable } from "api/club";
import '../../assets/css/custom.css';
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { toast } from "react-toastify";
import clubTablesData from '../../assets/json/patextdata.json';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch } from 'react-redux';
const ClubTables = () => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loadTableData, setLoadTableData] = useState(false);
    const [tables, setTables] = useState(null);
    const [tableNumber, setTableNumber] = useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const club_id = searchParams.get('club_id');
    const notable = searchParams.get('notable');
    const [table_id, setTableID] = useState(null);
    const [errors, setErrors] = useState({});
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    // New state variables for editing title
    const [editingTitleIndex, setEditingTitleIndex] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');

    useEffect(() => {
        if (!loading) {
            getClubTables(club_id, user?.authtoken)
                .then((response) => {
                    if (response.status === 200) {
                        setTables(response.data.club_tables);
                    }
                }).catch((error) => {
                    if (error.response.status === 404 && notable) {
                        setVisible(true);
                        setTables(null);
                    }
                    if(error.response.status === 400) {
                        localStorage.clear();
                        dispatch({
                          type: 'LOGOUT',
                          payload: {
                          },
                        });
                        history.push('/login');
                    }
                });
        }
    }, [loading, loadTableData]);

    const openAddTableDialog = () => {
        setVisible(true);
    };

    const addTable = () => {
        if (validateForm()) {
            setLoading(true);
            const clubtabledata = {
                "table_number": tableNumber?.toString(),
                "club_id": club_id
            }
            const formData = new FormData();
            Object.entries(clubtabledata).forEach(([key, value]) => {
                formData.append(key, value);
            });

            addClubTable(clubtabledata, user?.authtoken)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(clubTablesData.clubTablesText.tablecreationmsg);
                    setTableNumber('');
                    setVisible(false);
                    setLoading(false);
                    setLoadTableData(!loadTableData);
                }
            }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                    error: error
                },
                });
                history.push("/something-went-wrong");
            });
        }
    }

    const handleClubTableDelete = (id) => {
        deleteClubTable(id, user?.authtoken)
        .then((response) => {
            if (response.status === 200) {
                toast.success(clubTablesData.clubTablesText.tabledeletemsg);
                setLoadTableData(!loadTableData);
            }
        }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        });
    }

    const handleAccept = (id) => {
        handleClubTableDelete(id);
    }

    const reject = () => { }

    const confirm = (id) => {
        confirmDialog({
            message: clubTablesData.clubTablesText.tabledialogcnfmsg,
            header: clubTablesData.clubTablesText.tabledialogcnfheader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            closeOnEscape: true,
            accept: () => handleAccept(id),
            reject
        });
    };

    const handleGenerateQR = (table_id) => {
        const tableData = {
            "table_id": table_id
        }
        generateTableQR(tableData, user?.authtoken)
        .then((response) => {
            const imageUrl = response.data.qr_code_url;
            const downloadLink = document.createElement('a');
            downloadLink.href = imageUrl;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadLink.href);
        }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        });
    }

    const header = (
        <img alt={clubTablesData.clubTablesText.imgalttext} src={clubtable} style={{ padding: '30px' }} />
    );

    const footer = (table, index) => {
        return (
            <>
                <Button label={clubTablesData.clubTablesText.qr} icon="pi pi-qrcode" className="normal-button" onClick={() => handleGenerateQR(table.ID)} />
                <Button label={clubTablesData.clubTablesText.delete} severity="danger" icon="pi pi-trash" style={{ marginLeft: '0.5em' }} onClick={() => confirm(table.ID)} />
            </>
        )
    };

    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button label={clubTablesData.clubTablesText.cancel} icon="pi pi-times" onClick={() => handleHideDialog()} className="p-button-text cancel-button" />
            <Button label={clubTablesData.clubTablesText.save} icon="pi pi-check" loading={loading} onClick={() => addTable()} autoFocus className="normal-button" />
        </div>
    );

    const validateField = (fieldName, value) => {
        let error = "";
        if (!value) {
            error = clubTablesData.clubTablesText.validateerrormsg;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: error
        }));

        return error;
    };

    const validateForm = () => {
        const newErrors = {};

        if (!tableNumber) {
            newErrors.table_number = clubTablesData.clubTablesText.validateerrormsg;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleTableNumber = (fieldname, value) => {
        setTableNumber(value);
        validateField(fieldname, value);
    }

    const handleHideDialog = () => {
        setVisible(false);
        setErrors({});
    }

    // New function to handle title editing
    const handleEditTitle = (index, id) => {
        setTableID(id);
        setEditingTitleIndex(index);
        setEditedTitle(tables[index].TableNumber);
    };
    // Cancel the edit for table
    const handleEditTitleCancel = (index, id) => {
        setTableID(null);
        setEditingTitleIndex(null);
        setEditedTitle('');
    };

    // New function to handle saving edited title
    const handleSaveTitle = (index) => {
        // Update the tables array with the edited title
        const updatedTables = [...tables];
        updatedTables[index].TableNumber = editedTitle;
        // Reset the editing state
        setTables(updatedTables);
        setEditingTitleIndex(null);
        setEditedTitle('');
        const reqbody = {
            "table_number": editedTitle,
            "club_id": club_id,
            "table_id": table_id
        }
        updateClubTable(reqbody, user?.authtoken)
        .then((response) => {
            if(response.status === 200) {
                toast.success("Table updated successfully!");
            }
        }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        });
    };

    const TableTitle = ({title}) => {
        return(
            <>
                <Tooltip mouseTrack={true} target=".d-inline-block" />
                <span class="d-inline-block text-truncate" style={{maxWidth: '200px', verticalAlign:'middle'}} data-pr-position="top" data-pr-tooltip={title}>{title}</span>
            </>
        )
    }

    const handleBack = () => {
        history.go(-1);
    }

    return (
        <>
            <ConfirmDialog />
            <Dialog header={clubTablesData.clubTablesText.addtable} visible={visible} style={{ width: '25rem' }} draggable={false} onHide={() => handleHideDialog()} footer={footerContent}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-group" style={{ width: "100%" }}>
                            <label>{clubTablesData.clubTablesText.tableno}<span className="redStar">*</span></label>
                            <InputText
                                className={`form-control ${errors.table_number ? "is-invalid" : ""} gray-placeholder`}
                                value={tableNumber}
                                name="table_number"
                                onChange={(event) => handleTableNumber("table_number", event.target.value)}
                                style={{ display: 'flex' }}
                                maxLength={3}
                                keyfilter="int"
                                placeholder={clubTablesData.clubTablesText.tableplaceholder}
                            />
                            {errors.table_number && (
                                <div className="invalid-feedback">{errors.table_number}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: "1%" }}>
                    <div className="col-6 text-left">
                        <Button label={clubTablesData.clubTablesText.back} icon="pi pi-angle-left" link style={{ textDecoration: "none" }} onClick={() => handleBack()} />
                    </div>
                    <div className="col-6 text-right buttonParent">
                        <Button label={clubTablesData.clubTablesText.addtable} icon="pi pi-plus" onClick={openAddTableDialog} className="normal-button btn-sm" />
                    </div>
                </div>
                <div className="row">
                    {
                        tables?.map((table, index) => {
                            return (
                                <div className="col-md-3 mb-4" key={index}>
                                    <div className="card flex justify-content-center" >
                                        <Card
                                            title={
                                                editingTitleIndex === index ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <InputText
                                                            maxLength={20}
                                                            value={editedTitle}
                                                            onChange={(e) => setEditedTitle(e.target.value)}
                                                            style={{ width: '100%', maxWidth: '80%',height:"35px", fontSize:"80%" }}
                                                        />
                                                        <Button rounded outlined icon="pi pi-check" onClick={() => handleSaveTitle(index)} style={{ marginLeft: '0.5em',borderRadius:"50%",height:"26px"}} />
                                                        <Button rounded outlined icon="pi pi-times" onClick={() => handleEditTitleCancel()} style={{ marginLeft: '0.5em',borderRadius:"50%",height:"26px", color:"#D32F2F"}} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <TableTitle title={table.TableNumber}/>
                                                        {editingTitleIndex !== index && (
                                                            <Button rounded outlined icon="pi pi-pencil" onClick={() => handleEditTitle(index, table.ID)} style={{ marginLeft: '0.5em',borderRadius:"50%"}} />
                                                        )}
                                                    </>
                                                )
                                            }
                                            footer={() => footer(table, index)}
                                            header={header}
                                            className="md:w-25rem"
                                            style={{ textAlign: 'center' }}
                                        >
                                            
                                        </Card>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default ClubTables;
