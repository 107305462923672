import axios from "axios";
import { apiPrefix } from "config";

export const addDigitalSignCategory = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/club-digital-sign-type`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteDigitalSignCategory = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/club-digital-sign-type/${id}`, {
        headers: {
            token: authtoken
        }
    });
}

export const updateDigitalSignCategory = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/club-digital-sign-type`, formData, {
        headers: {
            token: authtoken,
        } 
    });
}

export const getDigitalSignCategoriesOfClub = async(id, authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/club-digital-sign-types/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const getDigitalSignByCategory = async(id, authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/club-digital-signs/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const addDigitalSign = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/club-digital-sign`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const updateDigitalSign = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/club-digital-sign`, formData, {
        headers: {
            token: authtoken,
        }
    });
}



export const deleteDigitalSign = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/club-digital-sign/${id}`, {
        headers: {
            token: authtoken
        }
    });
}

