import React from "react";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
const SelectMultipleEntityFromDropdown = ({options,showDropdown,selectedOptions,setShowDropdown,handleSelectOption,handleRemoveOption}) => {
    return (
        <div className="form-group" controlId="mySelect">
            <div style={{position: 'relative'}}>
                <div className="card flex justify-content-center" style={{backgroundColor: "transparent"}}>
                    <span className="p-float-label">
                        <InputText id="username" onFocus={() => setShowDropdown(true)} // Show dropdown on input focus
                        style={{position: 'relative'}} className="form-control" />
                        <label htmlFor="username" style={{fontSize:"14px"}}>{selectedOptions.length === 0 ? "Select Associated Clubs" : ""}</label>
                    </span>
                </div>
                {showDropdown && (
                    <div style={{position: 'absolute',top: '100%',left: 0,width: '100%',border: '1px solid #ced4da',zIndex: 1,backgroundColor: 'white'}}>
                        {options
                            .filter(option => !selectedOptions.includes(option.value)) // Filter out selected options
                            .map(option => (
                                <div key={option.value} onClick={() => handleSelectOption(option.value)} style={{cursor: 'pointer',padding: '5px'}}>
                                    {option.label}
                                </div>
                            ))}
                    </div>
                )}
                <div style={{display: 'flex',flexWrap: 'wrap',position: 'absolute',top: '7px',left: '5px'}}>
                    {selectedOptions.map((option) => (
                        <Tag key={option} bg="secondary" className="me-2 mb-2" style={{cursor: 'pointer',color: '#fff',marginLeft: '5px', backgroundColor:'gray'}}>
                            {options.find((item) => item.value === option)?.label}
                            {<i style={{marginLeft: '5px',color: 'gold'}} onClick={() => handleRemoveOption(option)}>x</i>}
                        </Tag>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default SelectMultipleEntityFromDropdown;