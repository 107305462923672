import axios from "axios";
import { apiPrefix } from "config";

export const setUpClub = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/club`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const getClub = async(id,authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/club/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const getClubs = async(authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/clubs`, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteClub = async(id,authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/club/${id}`, {
        headers: {
            token: authtoken
        }
    });
}

export const getClubTables = async(club_id,authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/tables/${club_id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const addClubTable = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/table`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const updateClubTable = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/table`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteClubTable = async(table_id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/table/${table_id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const generateTableQR = async(tableData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/generate-branded-qrcode`, tableData, {
        headers: {
            token: authtoken,
        }
    });
}

export const getCityAndCountry = async(searchTerm, authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/search-cities/${searchTerm}`, {
        headers: {
            token: authtoken,
        }
    });
}


