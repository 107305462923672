import { useHistory } from 'react-router-dom';

export const redirectToLoginIfNotAuthenticated = (user) => {
  const history = useHistory();

  if (!user || !user?.authtoken) {
    // Redirect to the login page
    history.push('/login');
    return false;
  }
  return true;
};
