import axios from "axios";
import { apiPrefix } from "config";

export const getClubScreens = async(id,authtoken) => {
    return await axios.get(`${apiPrefix}/api/v1/sa/screens/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}

export const addClubScreen = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/screen`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const updateClubScreen = async(formData, authtoken) => {
    return await axios.post(`${apiPrefix}/api/v1/sa/screen`, formData, {
        headers: {
            token: authtoken,
        }
    });
}

export const deleteClubScreen = async(id, authtoken) => {
    return await axios.delete(`${apiPrefix}/api/v1/sa/screen/${id}`, {
        headers: {
            token: authtoken,
        }
    });
}